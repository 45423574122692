
body {
  margin: 0;
  font-family: 'Rubik', sans-serif;

  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: hsl(0, 0%, 88%)
  /* background: #17c0eb; */
}

a {
  color: hsl(0, 0%, 23%)
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.error-message {
  color: hsl(356, 75%, 53%);
  margin-bottom: 5px;
  font-style: italic;
}

.input-error {
  color: hsl(356, 75%, 53%);
  font-style: italic;
  height: 18px;
  font-size: 14px;
  text-align: right;
}

.error {
  border-color: hsl(356, 75%, 53%);
}

.link {
  cursor: pointer;
}

.link-underline {
  cursor: pointer;
  text-decoration: underline;
}

button.success {
  color: #00b894;
  border:0.1em solid #00b894;
}

input {
  display:inline-block;
  padding: 0.35em .8em;
  border:0.1em solid hsl(0, 0%, 23%);
  /* border-radius:0.12em; */
  box-sizing: border-box;
  text-decoration:none;
  font-family:'Roboto',sans-serif;
  font-weight:300;
  /* background-color: hsl(209, 15%, 28%); */
  color: hsl(0, 0%, 23%);
  transition: all 0.2s;
}

.btn.footer-btn {
  border-radius:0.20em;
  margin-top: 1em;
  margin-right: 0.6em;
  padding:0.5em 1.5em;
  color:hsl(0, 0%, 97%);
  /* font-size: 14px;
  font-weight: bold; */
  background-color: hsl(202, 83%, 41%);
  border: none; 
}

.btn.cancel-btn {
  border-radius:0.20em;
  margin-bottom: 1em;
  padding:0.5em 1.5em;
  color:hsl(0, 0%, 97%);
  /* font-size: 14px;
  font-weight: bold; */
  background-color: hsl(202, 83%, 41%);
  border: none; 
}

.btn.footer-btn:hover {
  color:hsl(0, 0%, 97%);
  background-color: hsl(204, 96%, 27%);
}

.footer-btn.inverse-footer-btn {
  color: hsl(202, 83%, 41%);
  background-color:hsl(0, 0%, 97%);
  border: hsl(204, 96%, 27%) solid 1px;
  /* font-size: 14px;
  font-weight: bold; */
}

.footer-btn.red-footer-btn {
  background-color: hsl(354, 85%, 44%);
}
.footer-btn.red-footer-btn:hover {
  background-color: hsl(350, 94%, 28%);
}
.footer-btn.green-footer-btn {
  background-color: hsl(164, 71%, 34%);
}
.footer-btn.green-footer-btn:hover {
  background-color: hsl(168, 80%, 23%);
}

  /* width: 40%; */
  /* 
  height: 36px;
  width: 140px;
  border-radius: 5px;
  color:hsl(0, 0%, 97%);
  font-size: 14px;
  font-weight: bold;
  background-color: hsl(202, 83%, 41%);
  border: none; 
  */
.btn.minimize-btn {
  margin-left: auto;
  margin-right: auto;
}

.btn {
  display:inline-block;
  padding:0.35em 1.2em;
  border:0.1em solid hsl(0, 0%, 23%);
  margin:0 0.3em 0.3em 0;
  border-radius:0.12em;
  box-sizing: border-box;
  text-decoration:none;
  font-family:'Roboto',sans-serif;
  font-weight:300;
  background-color: hsl(0, 0%, 97%);
  color: hsl(0, 0%, 23%);
  text-align:center;
  transition: all .75s;
}

.week-btn {
  /* width: 40%; */
  height: 36px;
  width: 140px;
  border-radius: 5px;
  color:hsl(0, 0%, 97%);
  font-size: 14px;
  font-weight: bold;
  background-color: hsl(202, 83%, 41%);
  border: none;
}

.week-btn:hover {
  background-color: hsl(204, 96%, 27%);
}

.week-picker {
  display: flex;
  flex-flow: row;
  justify-content: space-around;
  margin-top: 5px;
}


/* .btn:hover{
  cursor: pointer;
  color:hsl(0, 0%, 69%);
  background-color:hsl(0, 0%, 23%);
} */

.small-text {
  font-size: .75em;
}



.logo {
  color: hsl(0, 0%, 23%);
  font-size: 36px;
  text-align: center;
  margin-top: 20px;
}

.logo>a {
  text-decoration: none;
}

.logo-logged-out {
  margin-top: 48px;
}

.hidden { 
  display: none;
}

.left {
  float: left;
}

.right {
  float: right;
}

.center {
  margin-left: auto;
  margin-right: auto;
}

.header-button {
  display: inline;
  margin: 5px;
}

.header {
  background-color: hsl(44, 92%, 63%);

  color: hsl(0, 0%, 13%);
  height: 168px;
  width: 100%;
  display: inline-block;
  left:0;
  top: 0;
  /* position: absolute; */
  z-index: 99;
}

.double-header {
  margin-top: 10px;
  color:hsl(0, 0%, 13%);
}

.login-links {
  position: absolute;
  right: 24px;
  top: 10px;
  /* background-color: red; */
}

.login-links>a {
  color: hsl(0, 0%, 13%);
  /* text-decoration: none; */
}

.header-links {
  margin-left: auto;
  margin-right: auto;
  width: 45%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
}

.header-links>a {
  color: hsl(0, 0%, 13%);
  text-decoration: none;
}

.header-links>a:hover {
  text-decoration: underline;
}


.sidebar {
  height: 100vh;
  width: 100vw;
  position: absolute;
  color: white;
  background-color: hsl(0, 0%, 97%);
  /* background-color: #3D4852; */
  top: 0;
  left: 0;
  z-index: 101;
  -webkit-transition: right .5s; /* Safari prior 6.1 */
  transition: right .5s;
}

.blur {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  /* background-color: yellowgreen; */
  z-index: 100;
  backdrop-filter: blur(150px) grayscale(0%);
  /* backdrop-filter: grayscale(30%); */
}

.blur-jr {
  z-index: 101;
}

.hamburger-item {
  list-style-type: none;
  text-align: center;
  margin: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  font-size: 5vh;
  color: hsl(0, 0%, 23%);
  background-color: hsl(0, 0%, 97%);
  border-top: hsl(0, 0%, 62%) 1px solid;
  border-bottom: hsl(0, 0%, 62%) 1px solid;
}

/* .hamburger-item:hover {
  background-color: #303841;
} */


.percent {
  font-style: italic;
  margin-left: 5px;
}

.card {
  background-color: hsl(0, 0%, 97%);
  color: hsl(0, 0%, 23%);
  /* width: 70%; */
  padding: 25px;
  border-radius: 5px;
  margin-top: 50px;
  /* margin-left: auto;
  margin-right: auto; */
}
.purchase-card-header {
  margin-bottom: 1em;
}

.purchase-card-header-input-group {
  margin-bottom: 10px;
}
.purchase-card-header-input {
  /* position: relative; */
  /* right: 20vw; */
  display: inline-block;
  border-radius: 5px;
  /* float: right !important; */
}

.add.purchase-card {
  border-bottom: 2px solid #8298AD;
}

.purchase-card {
  border-bottom: 1px solid #8298AD;
}

.purchase-card-header-text {
  font-size: 120%;
  position: relative;
  top: 4px;
  width: 100px;
  display: inline-block;
  /* margin-bottom: 1em; */
}

.purchase-card-input {
  max-width: 28%;
  height: 30px;
}

.purchase-card-input-left {
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}
.remove-btn {
  /* margin-left: 0.3em; */
    /* width: 40%; */
    height: 30px;
    /* width: 40px; */
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    color:hsl(0, 0%, 97%);
    /* font-size: 14px; */
    /* font-weight: bold; */
    background-color: hsl(202, 83%, 41%);
    border: none;
}


.remove-btn:hover {
  background-color: hsl(204, 96%, 27%);
}


.recipe-card {
  position: relative;
  left: 20%;
  width: 60%;
  border-bottom: 1px solid #8298AD;
}

/* .summary-card {

} */

.date-string {
  text-align: center;
  font-size: 1.25em;
}

.hamburger {
  position: absolute;
  left: 25px;
  top: 20px;
}

.slice {
  background-color: hsl(0, 0%, 13%);
  height: 3px;
  width: 20px;
  border-radius: 30%;
  margin-bottom: 2px;
}

.greeting {
  margin: 10px;
}

.show-add-card {
  background-color: hsl(0, 0%, 97%);
  color: hsl(0, 0%, 23%);
  width: 5%;
  padding: 15px;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  top: 98px;
  position: fixed;

  border-bottom: 1px solid #8298AD;
  border-right: 1px solid #8298AD;
}

.grocery-list {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: white;
  color: black;
}

.grocery-list-content {
  margin-top: 25px;
  margin-left: 25px;
}

.recipe-completed {
  color:hsl(0, 0%, 62%) !important;
}

.recipe-step {
  margin-top: 5px;
}

.meal-name {
  display: inline-block;
  width: 140px;
  margin-right: 5px;
}

.modal-card {
  position: fixed;
  top: 33%;
  z-index: 101;
}

.big-modal-card {
  position: absolute;
  top: 10%;
  max-height: 90%;
  z-index: 101;
  min-width: 75%;
  margin-left: auto;
  margin-right: auto;
}

.login-modal-card {
  left: 25%;
  width: 50%;
  top: 10%;
  min-width: 500px;
  background-color: hsl(0, 0%, 97%);
}

.login-modal-title {
  text-align: center;
  font-size: 36px;
  margin-top: 24px;
  margin-bottom: 36px;
}

.login-modal-input {
  width: 100%;
  height: 48px;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 2px;

}

.mealplan-modal-input {
  width: 50%;
  height:30px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  margin-top: 10px;
  margin-bottom: 2px;

}

.login-modal-label {
  font-weight: bold;
  color: hsl(0, 0%, 23%);
}

.login-btn {
  width: 100%;
  height: 48px;
  border-radius: 5px;
  color:hsl(0, 0%, 97%);
  font-size: 18px;
  font-weight: bold;
  background-color: hsl(202, 83%, 41%);
  border: none;
}

.login-btn:hover {
  background-color: hsl(204, 96%, 27%);
}

.forgot-password {
  font-weight: bold;
}
.remember-forgot {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  margin-bottom: 36px;
}

.flex-cards {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: flex-start;
  /* align-content: flex-start; */
}

.flex-card {
  width: 30%;
  display: inline-block;
}

/* .budget-card {
  
} */

.budget-cards {
  position: relative;
  left: 20%;
  width: 60%;
}

.meal-prep-card {
  position: relative;
  left: 20%;
  width: 60%;
}

.my-recipes-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: flex-start;
}

.mini-recipe-card {
  border-bottom: 1px solid #8298AD;
  width: 240px;
  height: 100px;
}

.checkbox-label {
  display: inline-flex;
  cursor: pointer;
  position: relative;
}

.checkbox-label > span {
  color: hsl(0, 0%, 13%);
  padding: 0.5rem 0.25rem;
}

.checkbox-label > input {
  height: 24px;
  width: 24px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid hsl(0, 0%, 13%);
  border-radius: 4px;
  outline: none;
  transition-duration: 0.3s;
  background-color: hsl(0, 0%, 97%);
  cursor: pointer;
}

.checkbox-label > input:checked {
  border: 1px solid hsl(0, 0%, 97%);
  background-color: hsl(42, 87%, 55%);
}

.checkbox-label > input:checked + span::before {
  content: '\2713';
  display: block;
  text-align: center;
  color: hsl(0, 0%, 13%);
  position: absolute;
  left: 0.7rem;
  top: 0.2rem;
}

.checkbox-label > input:active {
  border: 2px solid hsl(0, 0%, 13%);
}

.ingredient-form {
  max-width: 28%;
  height: 30px;
}

.ingredient-form-left {
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}

.recipe-name {
  height: 30px;
  border-radius: 5px;
  width: 30%;
  margin-top: 10px;
}

.recipe-name-label {
  margin-top: 10px;
  font-size: 26px;
}
.step-form {
  height: 30px;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  max-width: unset !important;
  width: 80%;
}

.step-text {
  font-style: italic;
  font-size: 16px;
  color: hsl(0, 0%, 38%);
}

.edit-recipe-card {
  position: relative;
  left: 10%;
  width: 80%;
}

.reset-password-card {
  position: relative;
  left: 10%;
  width: 80%;
}

.rounded-text-input {
  height: 30px;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}

.printer-friendly-grocery-list {
  font-size: 18px;
}

.purchase-card-trip-name {
  font-size: 24px;
  margin-bottom: 20px;
  font-weight: 700;
}

.purchase-card-date {
  float: right;
}

.purchase-card-money {
  margin-top: 20px;
  /* font-size: 20px; */
  font-weight: 700;
}

.favorites-badge {
  display: inline-block;

  margin-right: 5px;
  min-width: 14px;
  min-height: 24px;
  padding-left: 5px;
  padding-right: 5px;

  text-align: center;
  line-height: 150%;
  vertical-align: middle;
  
  color: hsl(0, 0%, 97%);
  background-color: hsl(0, 0%, 23%);
  border-radius: 10px;
}

.recipe-search-container {
  display: inline-block;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.recipe-search {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 24px;

  background-color: hsl(0, 0%, 88%);
  border: none;
  outline: none;
  width: 40%;
  border-bottom: hsl(0, 0%, 49%) solid 4px;
  font-size: 24px;
  height: 36px;
}

.headline {
  color: hsl(0, 0%, 23%);
  font-size: 30px;
  text-align: center;
  margin-top: 20px;
}


@media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px)  {
  .login-modal-card {
    left: 15%;
    width: 50%;
    top: 10%;
    /* min-width: 500px; */
    background-color: hsl(0, 0%, 97%);
  }  
}

